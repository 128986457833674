<template>
  <div>
    <!-- <nav-header></nav-header> -->
    <router-view></router-view>
    <nav-footer
      :email="this.email"
      :phone="this.phone"
      :name="this.name"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
export default {
  name: "home",
  components: {
    NavFooter,
  },
  data() {
    return {
      email: "邮箱： liuxing1990712@qq.com",
      phone: "电话：13911236285",
      icp : '备案号：湘ICP备2021021150',
      name: "湖南达善网络科技有限公司",
      addr: "地址：湖南省长沙市芙蓉区五里牌街道五一大道158号和谐潇湘大厦2207",
    };
  },
};
</script>
<style lang="" scoped></style>
