<template>
  <!-- 动画 -->
  <transition name="slide">
    <div class="my_modal" v-show="showModal">
      <!-- 遮罩层 -->
      <div class="mask"></div>
      <!-- 弹框 -->
      <div class="my_modal-dialog">
        <!-- 头部 -->
        <div class="modal-header">
          <!-- 标题 -->
          <span>{{ title }}</span>
          <!-- 关闭按钮 -->
          <a href="javascript:;" class="icon-close" @click="$emit('cancel')"></a>
        </div>
        <!-- 主体 -->
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <!-- 按钮尾部 -->
        <div class="modal-footer">
          <a
            href="javascript:;"
            class="btn"
            v-if="btnType == 1"
            @click="$emit('submit')"
            >{{ sureText }}</a
          >
          <a
            href="javascript:;"
            class="btn"
            v-if="btnType == 2"
            @click="$emit('cancel')"
            >{{ cancelText }}</a
          >
          <div class="btn-group" v-if="btnType == 3">
            <a href="javascript:;" class="btn" @click="$emit('submit')">{{ sureText }}</a>
            <a href="javascript:;" class="btn btn-default" @click="$emit('cancel')">{{
              cancelText
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props: {
    // 弹框组件类型  小 small 、中 middle 、大 large 、表单 form
    modalType: {
      type: String,
      default: "form",
    },
    // 弹框标题
    title: String,
    // 按钮类型   1: 确定按钮 2: 取消按钮 3: 确定取消
    btnType: String,
    // 确定按钮文本
    sureText: {
      type: String,
      default: "确定",
    },
    // 取消按钮文本
    cancelText: {
      type: String,
      default: "取消",
    },
    // 是否展示弹框
    showModal: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
@import "./../assets/scss/base.scss";
@import "../assets/scss/config.scss";
@import "../assets/scss/mixin.scss";
@import "../assets/scss/modal.scss";
@import "../assets/scss/button.scss"
</style>
