<template>
  <div class="info">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽 -->
    <div class="container">
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>关于我们</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <!-- banenr -->
        <div class="i2-banner">
          <img src="/imgs/s2.jpg" alt="" />
        </div>
        <div class="i2-con">
          <h3>公司名称：湖南棣唐信息科技有限公司</h3>
          <h3>邮箱：csonline@139.com</h3>
          <h3>电话：0731-88799888</h3>
          <h3>ICP备案：湘ICP备18009503号-1</h3>
          <h3>公司地址：长沙高新开发区桐梓坡西路298号研发大楼一层南侧</h3>
          <p>
           湖南棣唐信息科技有限公司成立于2017年12月12日，注册地位于长沙高新开发区桐梓坡西路298号研发大楼一层南侧，法定代表人为王伟峰。经营范围包括信息系统集成服务；信息技术咨询服务；科技信息咨询服务；软件开发；游戏软件设计制作；文化活动的组织与策划；软件服务；利用信息网络经营游戏产品；网络游戏虚拟货币发行；网络游戏虚拟货币销售；第二类增值电信业务中的呼叫中心业务、信息服务业务（仅限互联网信息服务）。（依法须经批准的项目，经相关部门批准后方可开展经营活动）湖南棣唐信息科技有限公司具有1处分支机构。
          </p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "/",
    };
  },
  mounted() {
      document.title = "棣唐信息 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  background-color: #f0f0f0;
  // banner
  .i2-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  // 定宽布局
  .container {
    @include flex();
    // height: 800px;
    margin-bottom: 30px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 70%;
      height: 100%;
      background: #fff;
      padding: 40px;
      box-sizing: border-box;
      text-align: center;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        p {
          font-size: 13px;
          line-height: 3.2em;
          color: #4e4b4b;
          margin: 15px auto;
          padding: 10px;
          box-sizing: border-box;
          text-align: left;
        }
        h3 {
          font-size: 18px;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
