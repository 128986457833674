<template>
  <div class="agr">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div class="container">
      <div class="my-title">
        <h2>未成年人限制条款</h2>
      </div>
      <div class="my-con">
        <p style="white-space: normal"> 随着网络在青少年中的普及，未成年人接触网络已经成为普遍现象。为保护未成年人健康参，在政府进一步加强行业管理的前提下，家长也应当加强监护引导。为此，我们为未成年人提供以下意见：</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
         一、主动控制网络时间。网络只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络中的行为和体验。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          二、不参与可能耗费较多时间的网络。未成年人登录后只有周末时间能上线4个小时。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          三、不要将网络当作精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠网络来缓解压力。
        </p>
      
       
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
            四、养成积极健康的心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
             五、注意保护个人信息。包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。
        </p>
        
        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "agreement",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "棣唐信息 ";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.agr {
  .container {
    text-align: center;
    padding-top: 30px;
    // 标题
    .my-title {
      font-size: 28px;
      font-weight: bold;
    }
    // 主容器
    .my-con {
      margin: 30px auto;
      border: 1px solid #ccc;
      font-size: 18px;
      text-align: left;
      line-height: 40px;
      padding: 20px;
      p {
        margin: 10px auto;
      }
    }
  }
}
</style>
