<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // 获取到 user 用户信息
      user: JSON.parse(window.localStorage.getItem('user')),
    }
  },
  beforeMount() {
    this.ysuser()
  },
  mounted() {
    this.ysuser()
  },
  methods: {
    ysuser() {
      if (!this.user) {
        // 预设用户
        var custom_user = {
          Username: 'mxw',
          Password: 'mxw',
          rPhone: '123',
        }
        window.localStorage.setItem('user', JSON.stringify(custom_user))
      }
    },
  },
}
</script>

<style lang="scss">
@import './assets/scss/reset.scss';
@import './assets/scss/config.scss';
@import './assets/font/iconfont.css'
</style>