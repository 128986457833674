<template>
  <div class="nav-footer">
    <div class="w">
      <!-- 右侧 -->
      <div class="r">
        <p>
          <span>联系电话：0731-88799888</span>
        </p>
        <p>
          <span>联系邮箱：csonline@139.com</span>
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >ICP备案编号：湘ICP备18009503号-1</a
          >
        </p>
      </div>
      <!-- 左侧 -->
      <div class="l">
        <p>公司名称： 湖南棣唐信息科技有限公司</p>
        <p>联系地址：长沙高新开发区桐梓坡西路298号研发大楼一层南侧</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  height: 134px;
  background: #444;
  // margin-top: 30px;
  .w {
    height: 100%;
    @include flex();
    .l {
      p {
        font-size: 12px;
        color: #fafafa;
        margin: 15px 0;
        text-align: right;
      }
    }
    .r {
      text-align: left;
      p {
        font-size: 12px;
        color: #fafafa;
        margin: 15px 0;
        i {
          margin: 0 5px;
        }
        a {
          color: #fafafa;
        }
      }
    }
  }
}
</style>
