import 'babel-polyfill'
import Vue from 'vue'
import router from './router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer) 

/* <script src="https://cdn.bootcss.com/babel-polyfill/6.23.0/polyfill.min.js"></script> */

Vue.use(require('vue-wechat-title'))


Vue.config.productionTip = false
// 挂载 axios，可以 this 调用
Vue.use(VueAxios, axios)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')