<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 定宽布局 -->
    <div class="w">
      <!-- swiper 轮播 -->
      <div class="swiper">
        <!-- <img src="/imgs/banner.jpg" alt="" /> -->
        <div class="swiper-box">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
              <a href="javascript:;"><img :src="item.img" /></a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

      <!-- 动漫区块1 -->
      <h3>热门<span>动漫</span></h3>

      <!-- 动漫展示区块 -->
      <div class="cm1">
        <!-- 循环项 -->
        <a
          class="cm1-item"
          v-for="(item, index) in this.dataList"
          :key="index"
          @click="goDays(item.methods, item.id)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.title }}</span>
          <p
            style="color: #c60023; font-weight: bold; font-size: 14px; text-align: center"
          >
            阅读{{ item.pay }}
          </p>
        </a>
      </div>

      <!-- 动漫区块2 -->
      <h3>精彩<span>动漫</span></h3>

      <!-- 动漫展示区块 -->
      <div class="cm1">
        <!-- 循环项 -->
        <a
          class="cm1-item"
          v-for="(item, index) in this.dataList2"
          :key="index"
          @click="goDays(item.methods, item.id)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.title }}</span>
          <p
            style="color: #c60023; font-weight: bold; font-size: 14px; text-align: center"
          >
            阅读{{ item.pay }}
          </p>
        </a>
      </div>

      <!-- 动漫区块3 -->
      <h3>最新<span>动漫</span></h3>

      <!-- 动漫展示区块 -->
      <div class="cm1">
        <!-- 循环项 -->
        <a
          class="cm1-item"
          v-for="(item, index) in this.dataList3"
          :key="index"
          @click="goDays(item.methods, item.id)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.title }}</span>
          <p
            style="color: #c60023; font-weight: bold; font-size: 14px; text-align: center"
          >
            阅读{{ item.pay }}
          </p>
        </a>
      </div>

      <div class="banner">
        <img src="/imgs/banner.jpg" alt="" />
      </div>

      <!-- 分页器 -->
      <navigation
        :pages="pages"
        :current.sync="pageNo"
        @navpage="msgListView"
        style="text-align: center; display: none"
      ></navigation>
      <!-- modal 弹框 -->
      <modal
        title="图片"
        btnType="1"
        modalType="middle"
        :showModal="showModal"
        @submit="showModal = false"
        @cancel="showModal = false"
      >
        <template v-slot:body>
          <img :src="imgUrl" />
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavHeader from "./../components/NavHeader";
import navigation from "./../components/navigation";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 根数据
      dataList: [],
      dataList2: [],
      dataList3: [],
      // 总页数
      pages: 7,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      my_data3: [],
      // 是否显示弹框
      showModal: false,

      imgUrl: "",
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    navigation,
    Modal,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.init();
    document.title = "棣唐信息";
  },
  methods: {
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      console.log(this.curPage);
      this.init();
    },

    init() {
      // 渲染数据
      this.axios.get("/mock/a1.json").then((res) => {
        console.log(res);
        var data = res.data;
        // 根数据
        this.dataList = data;
      });

      this.axios.get("/mock/a2.json").then((res) => {
        var data = res.data;
        this.dataList2 = data;
      });
      this.axios.get("/mock/a3.json").then((res) => {
        var data = res.data;
        this.dataList3 = data;
      });
    },
    // 跳转 逻辑 封装
    goDays(methodsWords, type) {
      this[methodsWords](type);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + 1,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay(id) {
      // if (this.state == 0) {
      //   this.$router.push("/pay");
      //   return;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("点击登录去购买~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }

      this.$router.push({
        path: "/sound2/:" + 1,
        query: { type: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("http://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {
  .w {
    margin: 30px auto;
    // swiper 轮播 区块
    .swiper {
      width: 100%;
      height: 500px;
      margin: 30px 0;
    }
    //  标题
    h3 {
      width: 100%;
      font-size: 30px;
      color: #555;
      // font-weight: bold;
      text-align: left;
      margin: 60px 0;
      border-bottom: 5px solid #88ada6;
      span {
        font-size: 21px;
        color: #88ada6;
      }
    }

    // 简介
    h4 {
      color: #333;
      span {
        font-size: 12px;
        color: #888;
        width: 100%;
        // display: inline-block;
      }
    }
    // 动漫展示区块
    .cm1 {
      background: #fff;
      width: 100%;
      // height: 600px;
      display: flex;
      // justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin: 30px 0;
      padding: 20px;
      box-sizing: border-box;

      // 循环项
      .cm1-item {
        width: 245px;
        height: 370px;
        display: block;
        text-decoration: none;
        margin: 15px 0;
        margin-right: 10%;

        img {
          width: 100%;
          height: 340px;
        }

        span {
          width: 100%;
          display: block;
          height: 30px;
          text-align: center;
          font-size: 14px;
          line-height: 30px;
          text-decoration: none;
        }
      }
    }

    // banner
    .banner {
      width: 100%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
