<template>
  <div class="agr">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <div class="my-title">
        <h2>交易纠纷处理</h2>
      </div>
      <div class="my-con">
         <!-- <h4>纠纷处理人：张磊</h4>
        <h4>电话：0571-87756089</h4> -->

        <p style="white-space: normal">湖南棣唐信息科技有限公司（以下简称棣唐信息）在此特别提醒用户在注册、登录本站前请务必仔细阅读本《纠纷处理方式》（未成年人应当在其法定监护人陪同下阅读）中的各个条款，以确保您在享受本站服务的同时维护自身合法权益：</p>

        <p style="white-space: normal">1.用户之间纠纷处理方式</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （1）棣唐信息仅作为本站的运营商，如若本站用户之间发生纠纷且无法协商一致的，可向国家相关行政机关或司法机关寻求解决包括但不限于向公安机关报案、申请仲裁或向人民法院提起诉讼等。棣唐信息将会为实名注册用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或采取必要措施。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （2）本站用户之间发生纠纷的，也可向棣唐信息客服人员投诉并举证。用户需提供与其账号注册信息一致的个人有效身份证件、必要的国家行政或司法机关文件和其他棣唐信息要求提供的相关证据。经棣唐信息审核确认后，可以给予必要的协助和支持。
        </p>
       
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">用户与棣唐信息之间纠纷处理方式</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
           （1）如本站用户对棣唐信息的服务有任何异议的，可以向棣唐信息的客服人员投诉并举证。查证属实的，棣唐信息将立即更正并按照现行法律规定给予用户必要的补偿。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
         （2）如本网站用户对棣唐信息的服务有任何异议的，也可以向双方约定的棣唐信息公司所在地人民法院提起诉讼。
            抵制不良动漫 拒绝盗版动漫 注意自我保护 谨防受骗上当 适度动漫益脑 沉迷动漫伤身
            合理安排时间 享受健康生活
        </p>
       
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "agreement",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "棣唐信息 ";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.agr {
  .container {
    text-align: center;
    padding-top: 30px;
    // 标题
    .my-title {
      font-size: 28px;
      font-weight: bold;
    }
    // 主容器
    .my-con {
      margin: 30px auto;
      border: 1px solid #ccc;
      font-size: 18px;
      text-align: left;
      line-height: 60px;
      padding: 20px;
      h4 {
          margin: 40px 0;
          font-size: 16px;
          color: #666;
      }
      p {
        margin: 10px auto;
      }
    }
  }
}
</style>
