<template>
  <div class="nav-header">
    <!-- 定宽布局 -->
    <div class="container">
      <!-- 左侧 -->
      <div class="n-l">
        <!-- logo -->
        <div class="n-logo">
          <!-- <img src="/imgs/logo.png" alt="" /> -->
          <h2>棣唐信息</h2>
        </div>
        <!-- 导航列表 -->
        <div class="n-list">
          <a href="/">网站首页</a>
          <!-- <a href="/#/ac1">动漫资源</a> -->
          <!-- <a href="/#/ac2">动漫表情</a> -->
          <a href="/#/info">关于我们</a>
          <a href="/#/xy2">未成年人限制条款</a>
          <a href="/#/xy3">交易纠纷处理</a>

        </div>
      </div>
      <!-- 右侧 -->
      <div class="n-r">
        <a href="javascript:;" @click="goLogin()" v-show="this.LoggedShow">登录-注册</a>
        <!-- <span>|</span> -->
        <!-- <a href="javascript:;" @click="goRegister()" v-show="this.LoggedShow">注册</a> -->
        <a
          href="javascript:;"
          v-show="!this.LoggedShow"
          v-text="this.nv_user.Username"
        ></a>

        <a href="javascript:;" @click="tolg()" v-show="!this.LoggedShow">退出登录</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.$router.push("/index");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-header {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  height: 64px;
  box-shadow: 0 0 3px 1px #cfcbcb;
  z-index: 10;
  .container {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .n-l {
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      .n-logo {
        width: 200px;
        height: 60px;
        h2 {
          line-height: 60px;
          font-size: 18px;
          cursor: pointer;
          color: #88ada6;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .n-list {
        margin-left: 20px;
        a {
          font-size: 14px;
          margin: 0 17px;
          text-decoration: none;
          color: #333;
          transition: all 0.2s;
          font-weight: 500;
          &:hover {
            color: #88ada6;
          }
        }
      }
    }
    .n-r {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        font-size: 14px;
        color: #959292;
        text-decoration: none;
      }
      span {
        color: #959292;
        font-size: 14px;
        margin: 0 8px;
      }
    }
  }
}
</style>
